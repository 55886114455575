var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VRow',{attrs:{"no-gutters":""}},[_c('VCol',[_c('VCard',{staticClass:"exam-timer-custom-shadow exam-timer-custom-rounded",attrs:{"data-test":"timer"}},[_c('VCardText',[_c('h3',{class:{
            'tt-light-red--text base' : _vm.showRedWarningText,
            'text-subtitle-1' : _vm.$vuetify.breakpoint.xsOnly,
            'tt-text-title-1' : _vm.$vuetify.breakpoint.smAndUp
          },attrs:{"data-test-label":"exam-timer"}},[(_vm.hasTime)?_c('span',{staticClass:"d-inline-flex align-center",class:{
              'tt-light-mono-100--text' : !_vm.showRedWarningText
            }},[_c('VIcon',{staticClass:"mr-2",attrs:{"size":"19"}},[_vm._v(" $stopwatch ")]),_vm._v(" "+_vm._s(_vm.timerMinutes)+":"+_vm._s(_vm.formattedSeconds)+" ")],1):_c('span',{staticClass:"tt-light-mono-46--text d-inline-flex align-center"},[_c('VIcon',{staticClass:"mr-2 tt-light-mono-46--text",attrs:{"size":"19"}},[_vm._v(" $stopwatch ")]),_vm._v(" 0:00 ")],1)]),_vm._t("text",function(){return [_c('p',{staticClass:"mb-0 mt-2 text-body-2",class:_vm.hasTime ? 'tt-light-mono-46--text' : 'tt-light-red--text base'},[_vm._v(" "+_vm._s(_vm.timerText)+" ")])]}),_vm._t("actions")],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }