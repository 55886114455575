<template>
  <VRow no-gutters>
    <VCol>
      <VCard
        class="exam-timer-custom-shadow exam-timer-custom-rounded"
        data-test="timer"
      >
        <VCardText>
          <h3
            data-test-label="exam-timer"
            :class="{
              'tt-light-red--text base' : showRedWarningText,
              'text-subtitle-1' : $vuetify.breakpoint.xsOnly,
              'tt-text-title-1' : $vuetify.breakpoint.smAndUp
            }"
          >
            <span
              v-if="hasTime"
              class="d-inline-flex align-center"
              :class="{
                'tt-light-mono-100--text' : !showRedWarningText
              }"
            >
              <VIcon
                class="mr-2"
                size="19"
              >
                $stopwatch
              </VIcon>
              {{ timerMinutes }}:{{ formattedSeconds }}
            </span>

            <!--            TODO отрефачить компонент и подумать, как возвращать таймер со значением 0:00 -->

            <span
              v-else
              class="tt-light-mono-46--text d-inline-flex align-center"
            >
              <VIcon
                class="mr-2 tt-light-mono-46--text"
                size="19"
              >
                $stopwatch
              </VIcon>
              0:00
            </span>
          </h3>
          <slot name="text">
            <p
              class="mb-0 mt-2 text-body-2"
              :class="hasTime ? 'tt-light-mono-46--text' : 'tt-light-red--text base'"
            >
              {{ timerText }}
            </p>
          </slot>
          <slot name="actions" />
        </VCardText>
      </VCard>
    </VCol>
  </VRow>
</template>

<script>
export default {
  name: 'ExamTimer',
  props: {

    hasTime: {
      type: Boolean,
      required: true,
    },
    timerSeconds: {
      type: Number,
      required: true,
      default: 0,
    },
    timerMinutes: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  computed: {
    /**
     * Хэлпер для правильного отображения секунд
     * @returns {string|number}
     */
    formattedSeconds() {
      if (!this.timerSeconds) return '00';
      if (this.timerSeconds < 10 && this.timerSeconds > 0) return `0${this.timerSeconds}`;

      return this.timerSeconds;
    },
    showRedWarningText() {
      return !this.timerMinutes && this.timerSeconds <= 10;
    },
    timerText() {
      return this.hasTime ? this.$t('ExamTimer.limitedTime') : this.$t('ExamTimer.overTime');
    },
  },
};
</script>

<style lang="scss">
.exam-timer {
  &-custom-rounded {
    border-radius: 8px !important;
  }

  &-custom-shadow {
    box-shadow: 0 2px 24px rgba(11, 18, 24, 0.08) !important;
  }
}
</style>
