
import Vue from 'vue';

import TestView from '@/components/ui/TestView.vue';
import InstrumentReviewHeader from '@/components/multileveltest/review/InstrumentReviewHeader.vue';
import QuestionImages from '@/components/ui/QuestionImages';
import MultileveltestAnswers from '@/components/multileveltest/question/MultileveltestAnswers.vue';
import ExamTimer from '@/components/multileveltest/review/ExamTimer.vue';
import DialogComponent from '@/components/ui/DialogComponent';

import * as TestService from '@/services/api/lxp-multi-level-test';
import MultiLevelTest from '@/domains/multileveltest/Test';
import { getRemainingTime } from '@/helpers/time';

import {
  AnswersComponentContext,
  DialogActionTypes,
  IAnswer,
  IQuestion,
  IQuestionChoice,
  QuestionType,
} from '@/domains/multileveltest';
import { DateTimeISO8601, PlayerSessionStatus } from '@/domains/common';
import { Banner } from '@/types/banner';
import { ITestSessionAnswerFinishResponseData, ITestInfoGetResponseData } from '@/services/api/lxp-multi-level-test/types';

export default Vue.extend({
  name: 'MultileveltestQuestion',
  components: {
    TestView,
    InstrumentReviewHeader,
    QuestionImages,
    ExamTimer,
    DialogComponent,
    MultileveltestAnswers,
  },
  inject: ['Names'],
  data() {
    return {
      testInfo: {} as ITestInfoGetResponseData,
      test: new MultiLevelTest(),
      answerTimeout: 0,
      timer: 0,
      isLoadingBtn: false,
      shouldLoadNextQuestion: true,
      isVisibleTimer: true,
    };
  },

  computed: {
    question(): IQuestion {
      return this.test.question;
    },

    questionChoices(): IQuestionChoice[] {
      return this.test.question.choices || [];
    },

    playerSessionId(): number {
      return Number(this.$route.params.playerSessionId);
    },

    answer(): IAnswer {
      return this.test.answer;
    },

    isMonoQuestion(): boolean {
      return this.question.questionType === QuestionType.MONO;
    },

    description(): string {
      return this.isMonoQuestion
        ? this.$t('MultileveltestQuestion.descriptionMono')
        : this.$t('MultileveltestQuestion.descriptionMulty');
    },

    timerMinutes(): number {
      return Math.floor(this.answerTimeout / 60);
    },

    timerSeconds(): number {
      return Math.floor(this.answerTimeout % 60);
    },

    hasTimeAndVisibleTimer(): boolean {
      return this.answerTimeout > 0 || !this.isVisibleTimer;
    },

    stepId(): string {
      return this.$route.params.stepId;
    },
  },

  watch: {
    'test.playerSessionStatus': {
      async handler(status) {
        this.checkSessionStatus(status);
      },
    },
    answerTimeout(value: number) {
      if (value > 0) this.shouldLoadNextQuestion = false;
    },
  },

  created() {
    this.getTestInformation();
    this.getSessionQuestion();
  },

  beforeDestroy() {
    this.clearTimer();
  },

  methods: {
    async getTestInformation(): Promise<void> {
      if (!this.playerSessionId) return;
      try {
        const response = await TestService.testInfoGet({
          playerSessionId: this.playerSessionId,
        });
        if (response) this.testInfo = response;
      } catch (e) {
        console.error(e);
      }
    },

    /**
     *  Получаем вопрос с возможными ответами
     */
    async getSessionQuestion(): Promise<void> {
      this.clearPage();
      try {
        const response = await TestService.testSessionQuestionGet({ playerSessionId: this.playerSessionId })
          .catch(() => this.$di.notify.error({ content: this.$t('MultileveltestQuestion.errors.notFoundQuestion') }));
        if (response) {
          const {
            answer, playerSessionStatus, answerTimeout,
          } = response;

          this.isVisibleTimer = true;
          if (answerTimeout === 0) this.isVisibleTimer = false;

          /** Проверка статуса на COMPLETED */
          this.checkSessionStatus(playerSessionStatus);

          /** Если в ответе пришло значения старта таймера берем его, иначе запускаем новый таймер */
          const { startedAt } = answer;
          if (startedAt !== null) {
            const remainingTime = getRemainingTime(startedAt, answerTimeout);

            if (remainingTime <= 0) {
              await this.getAnswerFinishApi(answer.id);
              this.getSessionQuestion();
              return;
            }

            this.test = response;

            this.setTimer(startedAt, response.answerTimeout);
          } else {
            if (this.isVisibleTimer) await this.sessionAnswerStart(response.answer.id, response.answerTimeout);
            this.test = response;
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    /**
     * Запрос на запуск таймера на бэкенде и получение времени
     *  */
    async sessionAnswerStart(answerId: number, timeout: number): Promise<void> {
      try {
        const { startedAt } = await TestService.testSessionAnswerStart({
          playerSessionId: this.playerSessionId,
          answerId,
        });
        if (startedAt) this.setTimer(startedAt, timeout);
        return;
      } catch (error) {
        this.$di.notify.error({ content: this.$t('MultileveltestQuestion.errors.cantUpdateTimer') });
      }
    },

    /** Усталавниваем время и запускаем таймер на фронте */
    setTimer(startedTime: DateTimeISO8601, timeout: number): void {
      this.answerTimeout = getRemainingTime(startedTime, timeout);
      this.timerInitialization();
    },

    /**
     * Завершаем ответ
     * */
    async answerFinish(): Promise<void> {
      try {
        this.getAnswerFinishApi(this.answer.id);
      } catch (e) {
        this.$di.notify.error({
          content: (e as any).response.data.error.message,
        });
      }
    },

    /** Событие по клику кнопки */
    async next(): Promise<void> {
      const isHasCheckedRadio = !!(this.$refs.answers as AnswersComponentContext).selectedMono?.id;
      const isHasCheckedCheckbox = !!(this.$refs.answers as AnswersComponentContext).selectedPoly?.length;

      if (!isHasCheckedRadio && !isHasCheckedCheckbox && this.hasTimeAndVisibleTimer) {
        (this.$refs.dialogConfirmation as Banner).open();
      } else {
        this.nextQuestionHandler();
      }
    },

    /** Обработчик логики перехода к следующему вопросу */
    async nextQuestionHandler(): Promise<void> {
      this.clearTimer();
      this.isLoadingBtn = true;
      try {
        if (this.answerTimeout <= 0 && this.isVisibleTimer) {
          this.getSessionQuestion();
        } else if (await this.getAnswerFinishApi(this.answer.id)) this.getSessionQuestion();
        this.isLoadingBtn = false;
      } catch (e) {
        this.$di.notify.error({
          content: (e as any).response.data.error.message,
        });
      }
    },

    getAnswerFinishApi(answerId: number): Promise<ITestSessionAnswerFinishResponseData> {
      return TestService.testSessionAnswerFinish({
        playerSessionId: this.playerSessionId,
        answerId,
      });
    },

    dialogAction(action: DialogActionTypes): void {
      if (action === DialogActionTypes.CANCEL) {
        (this.$refs.dialogConfirmation as Banner).close();
      } else {
        this.nextQuestionHandler();
        (this.$refs.dialogConfirmation as Banner).close();
      }
    },

    /**
     * Вспомогательные функции
     */
    async decreaseTime(): Promise<void> {
      if (this.answerTimeout > 0) {
        this.answerTimeout -= 1;
      } else {
        await this.getAnswerFinishApi(this.answer.id);
        this.clearTimer();
      }
    },

    clearTimer(): void {
      if (this.timer) {
        clearInterval(this.timer);
      }
    },

    timerInitialization(): void {
      this.timer = Number(setInterval(this.decreaseTime, 1000));
    },

    checkSessionStatus(status: PlayerSessionStatus): void {
      if (status === PlayerSessionStatus.COMPLETED) {
        this.clearTimer();

        this.$emit('step:completed', { stepId: this.stepId });

        this.$router.replace({
          name: this.Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_MULTILEVELTEST_RESULT,
          params: {
            playerSessionId: this.playerSessionId.toString(),
            stepId: this.stepId,
          },
        });
      }
    },

    clearPage(): void {
      this.clearTimer();
      this.isLoadingBtn = false;
    },

    updateAnswers(answers: Record<IQuestionChoice['id'], boolean>): void {
      try {
        TestService.testSessionAnswerUpdate({
          playerSessionId: this.playerSessionId,
          answerId: this.answer.id,
          answers,
        });
      } catch (e) {
        this.$di.notify.error({
          content: (e as any).response.data.error.message,
        });
      }
    },

    finishTest() {
      this.$router.push({
        name: this.Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_MULTILEVELTEST_VIEW,
        params: {
          playerSessionId: this.$route.params.playerSessionId,
          stepId: this.$route.params.stepId,
        },
      });
    },
  },
});
