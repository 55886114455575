import { PlayerSessionStatus } from '@/domains/common';
import { ITestSessionQuestionsGetResponseData } from '@/services/api/lxp-multi-level-test/types';
import {
  IAnswer,
  IQuestion,
} from './index';

export default class MultiLevelTest implements ITestSessionQuestionsGetResponseData {
  constructor() {
    this.answer = {} as IAnswer;
    this.question = {} as IQuestion;
    this.showRightAnswer = false;
    this.playerSessionStatus = PlayerSessionStatus.NEW;
    this.answerTimeout = 0;
  }

    answer: IAnswer;

    question: IQuestion

    showRightAnswer: boolean;

    playerSessionStatus: PlayerSessionStatus

    answerTimeout: number
}
